/* reset css and normalize values */
html,
body {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
cite,
code,
del,
dfn,
em,
img,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dd,
dl,
dt,
li,
ol,
ul,
fieldset,
form,
label,
legend,
button,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

ol,
ul {
  list-style: none;
}

q:before,
q:after,
blockquote:before,
blockquote:after {
  content: '';
}

html {
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a:focus {
  outline: thin dotted;
}

a:hover,
a:active {
  outline: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}

audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  border: 0;
  -ms-interpolation-mode: bicubic;
}

button,
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle;
}

button,
input {
  line-height: normal;
  *overflow: visible;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

button,
input[type='button'],
input[type='reset'],
input[type='submit'] {
  cursor: pointer;
  -webkit-appearance: button;
}

input[type='search'] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

textarea {
  overflow: auto;
  vertical-align: top;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

html,
button,
input,
textarea,
select {
  outline: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-style: 400;
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 40px;
  line-height: 48px;
}

h2 {
  font-size: 28px;
  line-height: 32px;
}

h3 {
  font-size: 24px;
  line-height: 28px;
}

h4 {
  font-size: 20px;
  line-height: 24px;
}

h5 {
  font-size: 14px;
  line-height: 20px;
}

p {
  font-size: 16px;
  line-height: 24px;
}

sub,
sup {
  font-size: 0.8em;
}

sub {
  bottom: -0.2em;
}

sup {
  top: -0.2em;
}

b {
  font-weight: bold;
}

em {
  font-style: italic;
}

input[type='text'],
input[type='email'],
input[type='password'],
textarea {
  font-size: 14px;
  line-height: 20px;
  font-style: 400;
  padding: 0.75rem 0;
  line-height: 1.5rem;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  outline: none;
}

input[type='text'] ::placeholder,
input[type='email'] ::placeholder,
input[type='password'] ::placeholder,
input[type='text'][disabled],
input[type='email'][disabled],
input[type='password'][disabled],
textarea[disabled] {
  opacity: 0.5;
}

input:-webkit-autofill {
  box-shadow: 0 0 0 1000px white inset;
  -webkit-box-shadow: 0 0 0 1000px white inset;
}
/* reset css and normalize values */
