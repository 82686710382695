@import 'styles/theme.scss';

.ProLabel {
  position: relative;
  display: inline-block;
  margin: 4px;
  background: linear-gradient(to bottom right, $izo_pro_pink, $izo_blue);
  font-family: $fontHeader;
  color: $izo_white;
  font-weight: 900;
  font-size: 8px;
  padding: 4px 8px;
  border-radius: 24px;
}
