@import 'styles/theme.scss';

.Tooltip {
  position: absolute;
  z-index: 3;
  pointer-events: none !important;
  text-align: center;

  &.top {
    top: -8px;
    left: 50%;
    transform: translate(-50%, -100%);
  }

  &.bottom {
    bottom: -8px;
    left: 50%;
    transform: translate(-50%, 100%);
  }

  &.left {
    top: 50%;
    left: -8px;
    transform: translate(-100%, -50%);
  }

  &.right {
    top: 50%;
    right: -8px;
    transform: translate(100%, -50%);
  }

  p {
    transition: all 0.6s ease;
    padding: 8px;
    border-radius: 8px;
    border: 0;
    box-shadow: 0px 8px 16px 0 rgba(0, 0, 0, 0.2);
    background: $izo_dark_grey_1;
    color: $izo_white;
    font-family: $fontBody;
    font-size: 12px;
    font-weight: 300;
    width: 240px;
    line-height: 1.2;
    white-space: pre-wrap;

    &.invisible {
      opacity: 0;
      display: none;
    }

    &.visible {
      opacity: 1;
    }
  }
}
