// public.scss
// Define all global styles for the Public UI in this file

// Use of mixins and inheritance strongly encouraged!
// All variables and mixins (generally SASS resources)
// should be defined in theme.scss for import capability
@import './theme.scss';

#Spire {
  position: fixed;
  width: 100vw;
  height: var(--vh, 100vh);
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  font-size: 16px;
  font-family: $fontBody;
  background: $izo_black;
  color: $izo_white;
  padding: 0 $pageMargin;
  overflow-y: auto;
  border-radius: 0;

  @include mobile {
    padding: 0 $pageMarginMobile;
  }
}

* {
  box-sizing: border-box;
}

img,
svg {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

input,
textarea {
  font-family: $fontBody;
}

body {
  font-family: $fontBody;
  font-weight: 400;
  color: $izo_white;
}

a {
  color: unset;
  text-decoration: unset;
}

p {
  line-height: 1.4;
}

button {
  color: $izo_white;
}

::-webkit-scrollbar {
  display: none;
  height: 0px;
  width: 0px;
  background: $izo_transparent;
  color: $izo_transparent;
}
