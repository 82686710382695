@import 'styles/theme.scss';

#AddComment {
  position: absolute;
  left: -24px;
  transform: translateX(-100%);
  margin: 0;

  @include mobile {
    left: 4px;
  }
}

#NewComment {
  position: absolute;
  bottom: 8px;
  left: 0;
  transform: translateX(-100%);
  background: $izo_dark_grey_1;
  border-radius: 8px;
  width: 280px;
  padding: 16px;
  z-index: 23;

  @include mobile {
    right: unset;
    border-radius: 0;
    left: 50%;
    width: 100vw;
    bottom: -48px;
    transform: translate(-50%, -32px);
    align-items: center;
  }

  #ProfileWrapper {
    @include flexLeft;
    padding: 4px 0;
    font-size: 14px;
    h5 {
      margin-left: 4px;
      font-size: inherit;
    }
    button {
      margin-left: auto;
      font-size: inherit;
    }
  }

  #InputWrapper {
    position: relative;
    padding: 4px 0 8px 0;
    textarea {
      background: $izo_black;
    }
    button {
      position: absolute;
      padding: 0;
      bottom: 12px;
      right: 10px;
      img {
        width: 18px;
        height: 18px;
      }
    }
  }

  p {
    font-size: 14px;
    color: $izo_light_grey_1;
  }
}

#EmojiWrapper {
  width: 100%;
  animation: fade-in 0.16s ease;
  max-height: 200px;
  margin-bottom: 8px;

  .emoji-mart-dark {
    max-height: 200px;
    width: 100% !important;
    overflow: auto;
    border: 0;
    background: $izo_dark_grey_1;
  }
  .emoji-mart-scroll {
    padding: 0;
  }
  .emoji-mart-category-label span {
    background: $izo_dark_grey_1;
    font-family: $fontHeader;
    font-size: 14px;
    font-weight: 900;
  }
  .emoji-mart-anchor {
    padding: 8px 0 4px 0;
  }

  .emoji-mart-anchor-selected {
    color: $izo_blue !important;
  }

  .emoji-mart-anchor-bar {
    background: $izo_blue !important;
  }
  .emoji-mart-preview {
    display: none;
  }
  .emoji-mart-search {
    width: 100%;
    * {
      box-sizing: border-box;
    }
    input {
      font-size: 12px;
      border: 0;
      background: $izo_dark_grey_1;
    }
    button {
      margin: 0;
      top: 0;
      svg {
        height: 10px;
      }
    }
  }
  .emoji-mart button:hover {
    background: $izo_transparent;
  }
}
