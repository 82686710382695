@import 'styles/theme.scss';

#Playhead {
  position: fixed;
  left: $pageMargin;
  bottom: $footerSize;
  z-index: 11;
  cursor: pointer;
  width: calc(100% - (#{$pageMargin} + #{$pageMargin}));
  height: 64px;
  background: $izo_dark_grey_1;
  border-radius: 8px;
  box-shadow: 0 0 16px 0px rgba($izo_black, 0.8);
  z-index: 22;

  @include mobile {
    left: $pageMarginMobile;
    bottom: $footerSizeMobile;
    width: calc(100% - (#{$pageMarginMobile} + #{$pageMarginMobile}));
  }

  #Elapsed {
    position: absolute;
    bottom: 8px;
    left: 8px;
    font-size: 14px;
    color: $izo_light_grey_1;
  }

  #Duration {
    position: absolute;
    bottom: 8px;
    right: 8px;
    font-size: 14px;
    color: $izo_light_grey_1;
  }

  #PlayheadElapsed {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 8px 0 0 8px;
    z-index: -1;
    background: $izo_medium_grey_1;
    height: 100%;
    pointer-events: none;
    transition: all 0.1s ease;

    &.playing {
      transition: all 0.5s linear;
    }

    &.scrubbed {
      transition: all 0.2s ease;
    }
  }

  #PlayheadHandle {
    position: relative;
    transition: all 0.1s ease;
    top: 0px;
    height: 100%;
    min-height: 100%;
    width: 3px;
    background: $izo_white;
    border-radius: 8px;
    cursor: grab;
    z-index: 26;
    box-shadow: 0px 4px 16px 0px rgba($izo_black, 0.64);

    &.playing {
      transition: all 0.5s linear;
    }

    &.scrubbed {
      transition: all 0.2s ease;
    }

    @include mobile {
      width: 6px;
    }

    &:active {
      cursor: grabbing;
      height: calc(100% + 8px);
      margin-top: -4px;
      width: 4px;
      box-shadow: 0px 8px 16px 1px rgba($izo_black, 0.64);

      @include mobile {
        height: 100%;
        margin-top: 0;
        width: 7px;
      }
    }

    &:after {
      // add clickable space around playhead to make grabbing easier
      content: '';
      position: absolute;
      top: 0;
      left: -16px;
      height: 100%;
      width: 32px;

      @include mobile {
        width: 16px;
        left: -8px;
      }
    }
  }

  #Minimap {
    opacity: 0;
    animation: fade-in 0.32s ease forwards 0.32s;
    &.song {
      display: none;
    }
  }
}
