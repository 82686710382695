@import 'styles/theme.scss';

#RepairCompare {
  .Playheads {
    @include flexCenter;
    justify-content: space-around;
    #Original,
    #Repaired {
      width: calc(50% - 32px);
      padding: 16px;
      margin: 16px auto;
      text-align: center;

      h3 {
        font-size: 18px;
        font-weight: 900;
        margin-bottom: 16px;
      }

      .Playhead {
        margin: auto;
        .playing,
        .paused {
          @include flexCenter;
          height: 96px;
          width: 96px;
          border-radius: 50%;
          margin: 0 auto 16px auto;
          background: linear-gradient(to bottom right, $izo_teal, $izo_purple);

          img {
            width: 48px;
            height: 48px;
          }

          svg {
            position: absolute;
            transition: all 0.32s ease;
            left: -4px;
            top: -4px;
            circle {
              transition: all 0.32s ease-in-out !important;
              transition-delay: 0s;
              stroke: $izo_white;
              stroke-width: 4px;
              transform-origin: 50% 50%;
              fill: transparent;
              stroke-dasharray: 327px 327px;
            }

            @include mobile {
              left: -8px;
              top: -6px;
            }
          }
        }

        div {
          @include flexCenter;
        }
        .ff,
        .rw {
          cursor: pointer;
          background: none;
          padding: 0;
          margin: 0 12px;
          vertical-align: -5%;
          i {
            svg {
              transition: color 0s ease 0s;
              width: 16px;
              color: $izo_light_grey_1 !important;
            }
          }
          &:hover i svg {
            color: $izo_white !important;
          }
        }
      }
    }
  }
}
