@import 'styles/theme.scss';

.ProfileIcon {
  @include flexCenter;
  position: relative;
  flex-shrink: 0;
  height: 42px;
  width: 42px;
  border-radius: 50%;
  background: $izo_spire_gradient;
  overflow: hidden;
  padding: 0;
  margin: 0;

  &.withBorder {
    border: 2px solid $izo_white;
  }

  &.withIcon {
    background: $izo_dark_grey_1;
  }

  @include mobile {
    height: 36px;
    width: 36px;
  }

  h4 {
    font-weight: 900;
    font-size: 16px;
    font-family: $fontHeader;
    text-transform: capitalize;
    text-align: center;
  }

  img {
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    margin: -1px 0 0 -1px;
    object-fit: cover;
    padding: 0;
    margin: 0;
  }
}
