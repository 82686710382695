@import 'styles/theme.scss';

#BeatToSpire {
  @include flexCenter;
  flex-direction: column;

  * {
    z-index: 1;
  }

  > .izo_heading {
    margin-top: 16px;
  }

  p {
    margin: 12px 0 24px 0;
  }

  .repairDropdown {
    margin: 16px 0 32px 0;
    width: 100%;
    max-width: 360px;
    @include flexCenter;
    justify-content: space-between;
    h6 {
      font-weight: 900;
    }
  }

  #FileSizeError {
    text-align: center;
    white-space: pre-wrap;
  }

  #AddAnotherFile {
    position: relative;
    display: inline-block;
    text-align: left;
    margin: 12px 16px;
    text-decoration: underline;
    cursor: pointer;
    transition: all 0.16s ease;
    color: $izo_light_grey_1;
    &:hover {
      color: $izo_white;
      cursor: pointer;
    }

    input {
      cursor: pointer;
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }

  #DurationMismatch {
    width: 100%;
    font-size: 12px;
    color: $izo_teal;
    margin: 8px 0;
  }

  #TooManyFiles {
    width: 100%;
    font-size: 12px;
    color: $izo_red;
    margin-top: 16px;
  }

  .FileUploads {
    text-align: center;
    margin: 16px 0 48px 0;
    width: 100%;
    h6 {
      width: 100%;
      text-align: left;
      font-weight: 900;
      margin-bottom: 12px;
    }
    .FileUpload {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: $izo_light_grey_1;
      width: 100%;
      margin: 6px 0;
      div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      span {
        font-size: 14px;
        opacity: 0.48;
        margin-left: auto;
      }
      .RemoveFile {
        padding: 0;
        margin: 0;
        margin-left: 8px;
        background: none;
      }
      .fa-play-circle {
        margin-right: 4px;
        font-size: 12px;
        color: $izo_teal;
      }
      .fa-check,
      .fa-circle-notch,
      .fa-cog,
      .fa-cloud,
      .fa-file-audio {
        color: $izo_white;
        margin-left: 8px;
      }
      .fa-times {
        @include flexCenter;
        text-align: center;
        pointer-events: all;
        font-size: 14px;
        transition: all 0.16s ease;
        &:hover {
          color: $izo_red;
          cursor: pointer;
        }
      }
    }
  }

  #ProgressBackground {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    transition: width 2s ease;
    z-index: 0;
    animation: opacity-pulse 1.6s ease infinite;
    background: $izo_purple;
  }

  #DropzoneContainer {
    width: 100%;
    height: 48vh;
  }

  h1 {
    font-weight: 900;
    padding: 8px 0 32px 0;
    text-align: left;
    width: 100%;

    @include mobile {
      font-size: 24px;
      padding: 4px 0 16px 0;
    }
  }

  #FileModal {
    @include flexCenter;
    flex-direction: column;
    width: 100%;

    .izo_input {
      width: 100%;

      &.ProjectTitle {
        width: calc(100% - 64px);
        margin-right: auto;
        input {
          font-size: 24px;
          font-weight: 900;
          font-family: $fontHeader;
        }

        label {
          font-family: $fontHeader;
          font-weight: 600;
        }

        @include mobile {
          width: calc(100% - 32px);
          input {
            font-size: 24px;
          }
        }
      }

      &.ProjectDescription {
        margin-top: 16px;

        label {
          font-family: $fontHeader;
          font-weight: 600;
        }
      }
    }
  }

  .ErrorButtons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .FooterButtons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .SubmitButton {
      margin: 0;
    }

    .CancelButton {
      background: none;
      font-weight: 500;
      text-decoration: underline;
      padding: 8px;
      margin: 0;
    }
  }

  @keyframes opacity-pulse {
    0% {
      opacity: 0.48;
    }
    50% {
      opacity: 0.72;
    }
    100% {
      opacity: 0.48;
    }
  }
}
