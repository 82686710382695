@import 'styles/theme.scss';

#UnavailableProject {
  position: relative;
  @include flexVerticalCenter;
  width: 100%;
  height: calc(100% - (#{$headerSize} + #{$footerSize}));
  overflow: hidden;
  animation: fade-in 1s forwards ease;
  text-align: center;
  z-index: 0;

  @include mobile {
    height: calc(100% - (#{$headerSizeMobile} + #{$footerSizeMobile}));
  }

  img {
    width: 100%;
    max-width: 640px;
    margin: 32px 0;
  }

  h3 {
    font-size: 28px;
    font-weight: 900;
    margin: 8px 16px;
    line-height: 1.2;
    @include mobile {
      font-size: 24px;
    }
  }

  p {
    font-size: 18px;
    line-height: 1.5;
    margin: 8px 16px;
    color: $izo_light_grey_1;
    strong {
      font-weight: 900;
    }
    @include mobile {
      font-size: 14px;
    }
    &.loading-delay {
      margin-top: 24px;
      opacity: 0;
      animation: fade-in-no-pointer-events 0.32s ease forwards 20s;

      strong {
        color: $izo_teal;
        text-decoration: underline;
        font-weight: 500;
        cursor: pointer;
      }
    }
  }

  #ErrorButtons {
    width: 100%;
    max-width: 640px;
    @include flexCenter;
    flex-wrap: wrap;
    margin-top: 16px;
    button {
      margin: 8px;
    }
    @include mobile {
      flex-direction: column;
    }
  }

  .AnimationWrapper {
    background: $izo_dark_grey_1;
    border-radius: 50%;
    overflow: hidden;
    animation: color-pulse infinite 7.57s;
    flex-shrink: 0;
  }
}

#ProjectAudio #UnavailableProject {
  height: 100%;
}

@keyframes fade-in-no-pointer-events {
  0% {
    opacity: 0;
    pointer-events: none;
  }
  99% {
    opacity: 1;
    pointer-events: none;
  }
  100% {
    opacity: 1;
    pointer-events: all;
  }
}

@keyframes color-pulse {
  0% {
    background: $izo_transparent;
  }
  15% {
    background: rgba(rgb(111, 87, 186), 0.7);
  }
  35% {
    background: rgba(rgb(255, 96, 37), 0.7);
  }
  50% {
    background: rgba(rgb(59, 132, 230), 0.7);
  }
  65% {
    background: rgba(rgb(43, 179, 137), 0.7);
  }
  80% {
    background: rgba(rgb(233, 204, 13), 0.8);
  }
  90% {
    background: rgba(rgb(233, 204, 13), 0.8);
  }
  100% {
    background: $izo_transparent;
  }
}
