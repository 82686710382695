@import 'styles/theme.scss';

#AdminCollections {
  height: 100%;
  .Header {
    @include flexCenter;
    justify-content: space-between;
    padding: 32px 0;

    button {
      margin: 0 16px;
    }
  }
}
