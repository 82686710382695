@import 'styles/theme.scss';

#AdminLogin {
  height: 100%;
  width: 100%;
  @include flexVerticalCenter;
  > img {
    width: 200px;
    margin-bottom: 32px;
  }
}
