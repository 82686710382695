@import 'styles/theme.scss';

#SpireLabs {
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: $izo_black;
  padding: 32px;

  @include mobile {
    padding: 0;
  }

  .izo_button {
    margin: 4px 0;
  }

  > img {
    width: 192px;
    height: 192px;
    border-radius: 50%;

    @include mobile {
      width: 160px;
      height: 160px;
    }
  }
}
