@import 'styles/theme.scss';

#CollectionsTable {
  overflow: auto;
  @include flexVerticalCenter;
  table {
    border-collapse: collapse;
    thead {
      tr {
        th {
          padding: 16px 8px;
          text-align: left;
          color: #fff;
          font-weight: 900;
          font-family: 'korolev';
          font-size: 20px;
          color: $izo_light_grey_1;

          &:first-child {
            padding: 16px 0 16px 16px;
            max-width: 32vw;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          &:last-child {
            text-align: right;
            padding: 16px 16px 16px 0;
          }
        }
      }
    }

    tbody {
      tr {
        cursor: pointer;
        transition: background 0.2s;

        &:hover {
          background: rgba($izo_blue, 0.2);
        }

        :first-child {
          border-radius: 8px 0 0 8px;
          max-width: 32vw;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        :last-child {
          border-radius: 0 8px 8px 0;
          overflow: visible;
        }

        td {
          padding: 25px 8px;
          vertical-align: middle;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 25%;
          &:first-child {
            font-weight: 900;
            color: $izo_teal;
            padding: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          &:last-child {
            text-align: right;
            padding: 16px 16px 16px 0;
          }

          h4 {
            color: $izo_teal;
            font-weight: 900;
          }
          i,
          svg {
            font-size: 16px;
          }
        }
      }
    }
  }

  p {
    margin: 16px;
  }

  img {
    width: 32%;
    margin: 32px;
  }

  button {
    margin: 8px;
  }

  strong {
    font-weight: 900;
  }
}
