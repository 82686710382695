@import 'styles/theme.scss';

#AdminClips {
  position: relative;
  width: 100%;
  height: 100%;

  #AdminAudioViews.clips #Tracks {
    padding: 0 0 64px 0;
  }
}
