@import 'styles/theme.scss';

#VisibilityToggle {
  position: relative;
  margin: 0 48px;
  width: 48px;
  height: 24px;
  background: $izo_blue;
  border-radius: 64px;
  cursor: pointer;
  @include flexCenter;
  justify-content: flex-start;
  flex-shrink: 0;

  &.on {
    background: $izo_red;
    justify-content: flex-end;
  }

  #ToggleNode {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: $izo_white;
  }

  .Pretext {
    position: absolute;
    left: -32px;
    top: 4px;
    font-size: 14px;
    font-family: $fontHeader;
    text-transform: uppercase;
  }

  .Posttext {
    position: absolute;
    right: -32px;
    top: 4px;
    font-size: 14px;
    font-family: $fontHeader;
    text-transform: uppercase;
  }
}
