@import 'styles/theme.scss';

#AudioControls {
  position: relative;
  @include flexCenter;
  justify-content: space-between;
  bottom: 0;
  left: 0;
  z-index: 23;
  width: 100%;
  height: $footerSize;
  transition: all 0.6s ease;

  @include mobile {
    height: $footerSizeMobile;
    padding: 0 16px;
    background: $izo_black;
  }

  .center {
    @include flexCenter;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  #Elapsed,
  #Duration {
    width: unset;
  }

  .LoadErrorIcon {
    pointer-events: none;
    font-size: 32px;
    position: absolute;
    left: calc(50% - 2px);
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    color: $izo_white;
    opacity: 0.32;
  }
}
