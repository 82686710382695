@import 'styles/theme.scss';

#AdminSong {
  position: relative;
  @include flexVerticalCenter;
  width: 100%;
  height: 100%;

  #UnavailableProject {
    height: 100%;
  }
}
