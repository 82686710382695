@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://use.typekit.net/yvk2fve.css');

// theme.scss contains all SASS variables and color definitions
// This file will be imported to all component .scss files
// It's important NOT to declare pure CSS styles here,
// as imports will cause duplications and messy/borderline
// impossible debugging from Dev tools

// START: Color Definitions
$izo_white: #ffffff;
$izo_black: #13161a;
$izo_dark_grey_1: #22272f;
$izo_dark_grey_2: #292e36;
$izo_dark_grey_3: #343840;
$izo_medium_grey_1: #34414c;
$izo_medium_grey_2: #495762;
$izo_light_grey_1: #81919d;
$izo_light_grey_2: #818488;
$izo_transparent: rgba(0, 0, 0, 0);
$izo_orange: #f26e26;
$izo_red: #ff4500;
$izo_pink: #f06eaa;
$izo_pro_pink: #942e7e;
$izo_blue: #3b84e6;
$izo_teal: #3fbfad;
$izo_purple: #6f57ba;
$izo_spire_gradient: linear-gradient(75deg, #7155c7 20%, #09b9b9 80%);
// END: Color Definitions

// START: Theme SASS Variables
$fontBody: 'Roboto';
$fontHeader: 'Korolev';
$mobile: 600px;
$headerSize: 96px;
$headerSizeMobile: 64px;
$viewButtonsSize: 48px;
$headerSizeMobile: 64px;
$footerSize: 128px;
$footerSizeMobile: 84px;
$playButtonSize: 72px;
$playButtonSizeMobile: 56px;
$playheadSize: 64px;
$pageMargin: 64px;
$pageMarginMobile: 16px;
// END: Theme SASS Variables

// START: mixins
@mixin flexLeft {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
@mixin flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
@mixin flexRight {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@mixin flexVerticalLeft {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
@mixin flexVerticalCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@mixin flexVerticalRight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
@mixin ellipsis {
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  min-width: 0 !important;
  display: block !important;
}
@mixin fadeIn {
  opacity: 0;
  animation: fade-in 0.2s ease forwards;
}
@mixin mobile {
  @media (max-width: #{$mobile}) {
    @content;
  }
}
// END: mixins
