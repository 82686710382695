@import '../../../styles/theme.scss';

.izo_input {
  position: relative;
  flex: 0 1 100%;
  overflow: hidden;
  border-radius: 8px;
  font-family: $fontBody;

  label {
    position: absolute;
    top: 15px;
    left: 15px;
    transition: all 0.1s ease;
    font-weight: 400;
    color: $izo_white;
    pointer-events: none;
  }

  > input,
  > textarea {
    width: 100%;
    border: none;
    padding: 12px 12px 4px 12px;
    font-size: 16px;
    transition: border 0.2s ease;
    background: $izo_dark_grey_1;
    border-radius: 8px;
    color: $izo_white;
    border: 3px solid $izo_transparent;

    &:focus {
      border: 3px solid $izo_blue;
      outline: none;
    }

    &.error {
      border: 3px solid $izo_red;
    }

    &.validated {
      border: 3px solid $izo_teal;
    }
  }

  input:focus + label,
  textarea:focus + label,
  &:not(.empty) label {
    color: $izo_blue;
    font-size: 10px;
    top: 6px;
  }
}

.InputError {
  margin: 4px 0 0 8px;
  font-size: 14px;
  color: $izo_red;
  font-weight: 500;
  font-family: $fontBody;
}
