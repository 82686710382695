@import 'styles/theme.scss';

.TimelineComment {
  position: absolute;
  bottom: 14px;
  z-index: 28;
  cursor: default;
  transition: left 0.2s ease;

  .Anchor {
    border-radius: 50%;
    box-shadow: -1px 1px 8px 0 rgba($izo_black, 1);
    z-index: 1;
    cursor: pointer;
  }

  .Body {
    position: absolute;
    bottom: 48px;
    left: -4px;
    background: $izo_black;
    padding: 8px 12px 4px 12px;
    max-width: 240px;
    min-width: 240px;
    border-radius: 8px 8px 8px 0;
    z-index: -1;
    border: 1px solid $izo_medium_grey_1;
    box-shadow: -1px 1px 8px 0 rgba($izo_black, 0.64);

    &.inactive {
      pointer-events: none;
      opacity: 0;
      transition: opacity 0.2s, pointer-events 0s 0s;
    }

    &.active {
      pointer-events: all;
      transition: opacity 0.2s, pointer-events 0s 0.3s;
    }

    &:after {
      content: '';
      position: absolute;
      left: -1px;
      bottom: -53px;
      height: 52px;
      width: 42px;
      background: $izo_black;
      border: 1px solid $izo_medium_grey_1;
      border-top: none;
      border-radius: 0 0 32px 32px;
      z-index: -1;
    }

    #CommentUser {
      @include flexLeft;
      width: 100%;
      aside {
        width: calc(100% - 64px);
        margin-left: 8px;
        h5:hover {
          text-decoration: underline;
          cursor: pointer;
        }
        p {
          color: $izo_blue;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: -1px;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    #CommentContent {
      padding: 8px 0;
      font-size: 16px;
      font-weight: 400;
      color: $izo_light_grey_1;
    }

    #CommentReactions {
      position: relative;
      @include flexLeft;
      flex-wrap: wrap;
      .Delete {
        position: absolute;
        bottom: 16px;
        right: 0;
        padding: 0;
        margin-right: 8px;
        opacity: 0.64;
        transition: opacity 0.2s;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}
