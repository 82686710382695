@import 'styles/theme.scss';

#PublicUserProfile {
  @include flexCenter;
  flex-direction: column;
  animation: fade-in 0.32s ease forwards;
  transition: all 0.32s ease;
  justify-content: flex-start;
  align-items: center;
  padding: 32px 16px 8px 16px;
  text-align: center;

  img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 16px;
  }

  h3 {
    font-size: 16px;
    color: $izo_light_grey_1;
    font-weight: 900;
    margin: 8px 0 0 0;
    line-height: 1.2;
  }

  p {
    line-height: 1.2;
    margin: 8px 0;
    white-space: pre-wrap;
  }

  .UserId {
    margin: 16px 0;
    font-size: 12px;
    strong {
      font-weight: 900;
    }
  }

  button {
    margin-top: 32px;
    padding: 8px 24px;
  }

  .EditProfile {
    font-size: 12px;
    color: $izo_light_grey_1;
    text-align: center;
    padding: 32px 16px 0 16px;
    span {
      text-decoration: underline;
      cursor: pointer;
      transition: all 0.16s ease;
      &:hover {
        color: $izo_white;
      }
    }
  }

  .Links {
    @include flexCenter;
    width: 100%;
    .Link {
      i,
      svg {
        font-size: 14px;
        margin-right: 4px;
      }
      margin: 0 8px;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
