@import 'styles/theme.scss';

#LandingQueryTool {
  position: relative;
  width: 100%;
  grid-column: span 2;

  #Buttons {
    @include flexCenter;
    width: 100%;
    justify-content: space-between;
  }

  #RightButtons {
    @include flexCenter;
    width: 50%;
    justify-content: space-between;
  }

  #QueryError {
    color: $izo_red !important;
    white-space: pre-wrap !important;
    overflow: visible !important;
    text-overflow: unset !important;
    button {
      display: inline-block;
      margin-right: 8px;
      color: $izo_red;
      background: rgba($izo_red, 0.4);
      border-radius: 50%;
      height: 18px;
      width: 18px;
      text-decoration: none;
    }
  }

  #TextAreas {
    width: 100%;
    @include flexCenter;
    justify-content: space-between;
    .izo_input {
      margin: 8px;
    }
  }
}
