@import 'styles/theme.scss';

#EditUserProfile {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  h1 {
    position: relative;
    margin: 12px 64px 8px 0;
    line-height: 1.1;
  }

  #DropzoneContainer {
    height: 200px;
    width: 200px;
    text-align: center;
    #Dropzone {
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 24px;
      h3 {
        white-space: pre-line;
      }
    }
  }

  .HiddenDropzone {
    position: relative;
    height: 160px;
    width: 160px;
    border-radius: 50%;
    overflow: hidden;
    background: rgba($izo_black, 0.32);
    flex-shrink: 0;

    @include mobile {
      height: 120px;
      width: 120px;
    }

    #Dropzone {
      position: absolute;
      transition: all 0.32s ease;
      opacity: 0;
      border: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      h3 {
        display: none;
      }
      &:hover {
        background: rgba($izo_black, 0.64);
        opacity: 1;
      }
    }

    #DisplayIcon {
      z-index: 0;
      position: absolute;
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      top: -2px;
      left: -2px;
      object-fit: cover;
      border: 0;
      outline: none;
      pointer-events: none;
    }
  }

  .ModalBody {
    @include flexCenter;
    justify-content: flex-start;
    width: 100%;
    margin: 32px 0 16px 0;

    @include mobile {
      flex-direction: column;
      margin: 16px 0;
    }

    .UserInputs {
      flex-direction: column;
      margin: 16px 0 32px 24px;
      width: 100%;

      @include mobile {
        margin: 0px 16px;
        text-align: center;
      }

      .izo_input {
        margin: 8px 0;
      }

      .Location {
        @include flexCenter;
        justify-content: space-between;

        .izo_input:first-child {
          margin-right: 8px;
        }
      }

      .Link {
        @include flexCenter;
        justify-content: space-between;

        .izo_input:first-child {
          flex: 1 0 33%;
          margin-right: 8px;
        }

        svg {
          cursor: pointer;
          margin-left: 8px;
        }
      }
    }

    h6 {
      width: 100%;
      text-align: left;
      padding: 8px 4px;
      font-weight: 900;
      font-size: 14px;
    }
  }

  .Error {
    text-align: center;
    margin: 8px;
    color: $izo_red;
  }

  .AddLinkButton {
    margin: 8px 4px;
    padding: 0;
    background: none;
    font-size: 14px;
    font-weight: 500;
    text-decoration: underline;
  }

  .SubmitButton {
    margin: 0;
  }

  .CancelButton {
    background: none;
    font-weight: 500;
    text-decoration: underline;
    padding: 8px;
    margin: 0;
  }

  .FooterButtons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
