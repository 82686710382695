@import 'styles/theme.scss';

.izo_button {
  @include flexCenter;
  position: relative;
  border: 0;
  user-select: none;
  cursor: pointer;
  white-space: nowrap;
  max-width: 100%;

  &.primary {
    padding: 8px 32px;
    font-size: 16px;
    font-weight: 600;
    font-family: $fontHeader;
    letter-spacing: 0.5px;
    border-radius: 64px;
    background: $izo_white;
    color: $izo_black;

    span {
      transition: all 0.2s;
      background: $izo_spire_gradient;
      background-clip: text;
      flex-grow: 1;
    }

    @media (hover: hover) and (pointer: fine) {
      &:not(.preventHoverEffects) {
        &:hover {
          span {
            color: $izo_transparent;
          }
        }
      }
    }
  }

  &.secondary {
    @include flexCenter;
    position: relative;
    padding: 8px 32px;
    font-size: 16px;
    font-weight: 600;
    font-family: $fontHeader;
    letter-spacing: 0.5px;
    border-radius: 64px;
    background: $izo_transparent;
    border: 2px solid $izo_white;
    color: $izo_white;
    transition: color 0.2s;
    overflow: hidden;
    background: none;
    transition: border-color 0.2s;
    @media (hover: hover) and (pointer: fine) {
      &:not(.preventHoverEffects) {
        &:hover {
          border-color: $izo_blue;
        }
      }
    }
  }

  &.tertiary {
    background: none;
    color: $izo_white;
    font-size: 16px;
    font-family: $fontHeader;
    letter-spacing: 0.5px;
    font-weight: 300;
    text-decoration: underline;
    transition: color 0.1s;
    padding-bottom: 2px;

    @media (hover: hover) and (pointer: fine) {
      &:not(.preventHoverEffects) {
        &:hover {
          color: $izo_blue;
        }
      }
    }
  }

  &.toggle {
    @include flexCenter;
    position: relative;
    padding: 8px 32px;
    font-size: 16px;
    font-weight: 600;
    font-family: $fontHeader;
    letter-spacing: 0.5px;
    border-radius: 64px;
    background: $izo_transparent;
    border: 2px solid $izo_white;
    color: $izo_white;
    transition: color 0.2s;
    overflow: hidden;
    background: none;
    transition: border-color 0.2s, background 0.2s;

    &.on {
      background: $izo_white;
      color: $izo_blue;
    }

    @media (hover: hover) and (pointer: fine) {
      &:not(.preventHoverEffects):not(.on) {
        &:hover {
          border-color: $izo_blue;
        }
      }
    }
  }

  &.simple {
    background: none;
    color: $izo_white;
    font-size: 16px;
    font-family: $fontHeader;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    padding: 8px 16px;

    span {
      position: relative;
      transition: all 0.2s;
      background: $izo_spire_gradient;
      background-clip: text;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0px;
        background: $izo_spire_gradient;
        transition: all 0.2s;
      }
    }

    .iconLeft,
    .iconRight {
      background: $izo_transparent;
      transition: all 0.2s;
      border-radius: 4px;
    }

    @media (hover: hover) and (pointer: fine) {
      &:not(.preventHoverEffects) {
        &:hover {
          span {
            color: $izo_transparent;
            &:after {
              bottom: -5px;
              height: 3px;
            }
          }
        }
      }
    }
  }

  &.close {
    background: none;
    font-size: 16px;
    position: absolute;
    top: 12px;
    right: 16px;

    span {
      color: $izo_white;
      transition: all 0.2s;
    }

    @media (hover: hover) and (pointer: fine) {
      &:not(.preventHoverEffects) {
        &:hover {
          span {
            color: $izo_blue;
          }
        }
      }
    }
  }

  &.fullWidth {
    width: 100%;
  }

  &.preventHoverEffects {
    cursor: default;
  }

  &.center {
    margin-left: auto;
    margin-right: auto;
  }

  .icon {
    object-fit: contain;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }

  .iconLeft {
    object-fit: contain;
    width: 16px;
    height: 16px;
    margin: 0 8px 0 0;
    flex-shrink: 0;
  }

  .iconRight {
    object-fit: contain;
    width: 16px;
    height: 16px;
    margin: 0 -0 0 8px;
    flex-shrink: 0;
  }

  &:disabled {
    color: $izo_medium_grey_2;
    cursor: not-allowed;
    &:not(.preventHoverEffects) {
      &:hover {
        // background: $izo_medium_grey_1;
      }
    }
  }
}
