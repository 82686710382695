@import 'styles/theme.scss';

#LandingNewCollection {
  overflow: hidden !important;
  @media (max-width: $mobile) {
    height: 50vh;
  }
  #DropzoneContainer {
    height: calc(100% - 64px);
    overflow: hidden;
    min-height: 120px;

    > div {
      height: 100%;
      background: $izo_black;
    }
  }
}
