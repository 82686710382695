@import 'styles/theme.scss';

#EnhanceOn,
#EnhanceOff {
  @include flexCenter;
  padding: 8px;
  border-radius: 8px;
  position: absolute;
  right: -20px;
  transform: translateX(100%);
  margin: 0;

  @include mobile {
    padding: 2px;
    right: -8px;
  }

  img {
    width: 26px;
    height: 26px;
    transition: background 0.2s;
    @include mobile {
      margin: 4px;
    }
  }
}

#EnhanceOff {
  img {
    background: $izo_spire_gradient;
  }
  span {
    background: $izo_spire_gradient;
    background-clip: text;
    @media (hover: hover) and (pointer: fine) {
      color: $izo_transparent;
    }
  }
}
#EnhanceOn {
  img {
    background: $izo_transparent;
  }
}

.clips {
  #EnhanceOn,
  #EnhanceOff {
    display: none;
  }
}
