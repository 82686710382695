@import 'styles/theme.scss';

#LandingOfferCodes {
  position: relative;
  width: 100%;
  grid-row: span 2;

  #expiration {
    width: 100%;
    text-align: left;
    font-family: $fontBody;
    background: $izo_black;
    color: $izo_white;
    border: none;
    border-radius: 8px;
    padding: 12px;
    font-size: 16px;
    margin: 8px 0;
  }

  ::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }

  h5 {
    font-size: 18px;
    font-weight: 900;
    text-transform: none;
  }

  h6 {
    color: $izo_light_grey_1;
    font-size: 12px;
    font-weight: 500;
    margin: 4px 0;
    font-family: $fontBody;
  }

  .Buttons {
    @include flexCenter;
    justify-content: space-between;
  }
}
