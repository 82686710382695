@import 'styles/theme.scss';

#Notifications {
  @include flexCenter;
  position: fixed;
  padding: 0;
  flex-direction: column;
  top: 16px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 21;
  transition: unset;

  .Notification {
    @include flexCenter;
    flex-shrink: 0;
    justify-content: space-between;
    background: #fff;
    padding: 12px 16px;
    border-radius: 8px;
    box-shadow: 0px 8px 32px -8px rgba(0, 0, 0, 0.4);
    color: #222;
    transition: all 0.5s ease;
    animation: drop-in 1s forwards ease;
    margin: 4px;
    span {
      padding-right: 32px;
      margin-left: 16px;
      white-space: nowrap;
    }
  }

  button {
    margin: 0;
    padding: 0;
    background: none;
    color: $izo_light_grey_1;
  }

  .exit {
    animation: fly-out 1s forwards ease;
  }

  .exit-with-delay {
    animation: fly-out 1s forwards ease 2s;
  }
}

@keyframes drop-in {
  from {
    transform: translateY(-500px);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes fly-out {
  from {
    transform: translateY(0);
    height: auto;
  }
  to {
    transform: translateY(-500px);
    height: 0;
    position: absolute;
    top: -100px;
  }
}

@include mobile {
  #CollectionProcessing {
    width: 100vw;
    left: 0;

    &:empty {
      height: 0;
      padding: 0;
    }

    .Notification {
      margin: 0 16px;
      left: 0;
      width: calc(100% - 32px);
    }
  }
}
