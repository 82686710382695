@import 'styles/theme.scss';

#EditCollection {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  flex-direction: column;

  h1 {
    margin: 12px 0;
  }

  #EditCollectionContent {
    max-width: 600px;
    width: 100%;
    margin: auto;
    padding-bottom: 96px;

    h6 {
      font-weight: 900;
      font-size: 14px;
      color: $izo_light_grey_1;
      margin-top: 8px;
    }

    #Expiration {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: auto;
      margin-bottom: 16px;
      h6 {
        margin-top: 0;
      }
      input[type='checkbox'] {
        display: inline;
        width: auto;
        margin: 8px 8px 8px 0;
      }
    }

    // input,
    // textarea {
    //   background: $izo_dark_grey_3;
    //   border-radius: 8px;
    //   padding: 8px 16px;
    //   color: $izo_white;
    //   margin: 8px auto;
    //   width: 100%;
    // }

    p {
      font-size: 14px;
      color: $izo_light_grey_1;
      text-align: center;
      padding: 8px 16px;
      i,
      svg {
        margin-right: 4px;
      }
      span {
        text-decoration: underline;
        cursor: pointer;
        transition: all 0.16s ease;
        &:hover {
          color: $izo_white;
        }
      }
    }

    .EditCollectionTitle {
      margin-bottom: 8px;
      input {
        font-weight: 900;
        font-family: $fontHeader;
        font-size: 20px;
        padding-top: 16px;
      }
      label {
        font-weight: 900;
        font-family: $fontHeader;
      }
    }

    .EditCollectionDescription {
      textarea {
        font-weight: 900;
        font-family: $fontHeader;
        padding-top: 16px;
      }
      label {
        font-weight: 900;
        font-family: $fontHeader;
      }
    }
  }

  .SubmitButton {
    position: absolute;
    bottom: 32px;
    right: 24px;
  }

  .CancelButton {
    position: absolute;
    bottom: 24px;
    left: 16px;
    background: none;
    font-weight: 500;
    text-decoration: underline;
    padding: 12px;
  }
}
