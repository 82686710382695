@import 'styles/theme.scss';

#PlayButton {
  background: rgba(0, 0, 0, 0) !important;
  position: relative;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $izo_transparent;
  border-radius: 50%;
  width: $playButtonSize;
  height: $playButtonSize;
  animation: fade-in-play 1s ease forwards;
  flex-shrink: 0;
  margin: 0;
  padding: 0;

  * {
    pointer-events: none;
  }

  &.loading {
    cursor: wait;
    img {
      opacity: 0.32;
    }
  }

  @include mobile {
    height: $playButtonSizeMobile;
    width: $playButtonSizeMobile;
  }

  > img {
    transition: all 0.32s ease;
    width: 48px;
  }

  @keyframes fade-in-play {
    to {
      opacity: 1;
    }
  }

  svg {
    position: absolute;
    transition: all 0.32s ease;
    left: 0px;
    top: 1px;
    animation: rotate infinite 3s linear;
    circle {
      transition: all 0.64s ease-in-out !important;
      transition-delay: 0s;
      stroke: $izo_white;
      stroke-width: 3px;
      transform-origin: 50% 50%;
      fill: transparent;
      stroke-dasharray: 260px 260px;
    }

    &.loaded {
      opacity: 0;
      transition-delay: 0.64s;
    }

    @include mobile {
      left: -8px;
      top: -6px;
    }
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
