@import 'styles/theme.scss';

#SpirePro {
  height: 100%;
  width: 100%;
  padding: 16px 32px;
  text-align: center;
  background: linear-gradient(to bottom, $izo_pro_pink, $izo_blue);

  @include mobile {
    padding: 16px;
  }

  .carousel,
  .carousel-root {
    width: calc(100% - 64px);
    margin: 32px auto;
    box-sizing: border-box;
    max-width: 250px;
    overflow: visible;
    * {
      overflow: visible !important;
    }

    @include mobile {
      max-width: 220px;
    }

    .control-arrow {
      height: 96px;
      top: 76px;
      &:hover {
        background: rgba(0, 0, 0, 0);
      }

      &.control-next {
        right: calc(-500px + 100%);
      }

      &.control-prev {
        left: calc(-500px + 100%);
      }
    }

    .slide {
      width: 100%;
      text-align: center;
      padding-bottom: 64px;
      padding: 32px 16px 48px 16px;
      background: rgba(0, 0, 0, 0);
      transition: opacity 0.32s ease;

      strong {
        font-weight: 900;
      }

      h2 {
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 12px;
        transition: opacity 0.32s ease;
      }
      img {
        width: 180px;
        height: 180px;
        object-fit: contain;
        margin-bottom: 16px;
      }
      p {
        font-size: 14px;
        line-height: 1.2;
        transition: opacity 0.32s ease;
      }
      aside {
        opacity: 0.64;
        font-size: 10px;
        margin-top: 8px;
        text-transform: uppercase;
        transition: opacity 0.32s ease;
      }
      &:not(.selected) {
        opacity: 0.24;
        cursor: pointer;
        h2,
        p,
        aside {
          opacity: 0;
        }
      }
    }
  }
  .control-dots {
    width: 100%;
    .dot {
      box-shadow: none !important;
    }
  }

  .carousel-status,
  .thumbs-wrapper {
    display: none;
  }

  #Subscribe {
    background: $izo_white;
    color: $izo_black;
    border-radius: 64px;
    margin: auto;
  }
}
