@import 'styles/theme.scss';

.Reaction {
  position: relative;
  @include flexCenter;
  width: 42px;
  height: 28px;
  margin: 2px;
  border-radius: 4px;
  padding: 4px;
  transition: all 0.16s ease;
  cursor: pointer;
  font-weight: 900;
  background: rgba($izo_light_grey_2, 0.32);

  &.withBackground {
    background: rgba($izo_blue, 0.84);
  }

  .ReactionUsers {
    display: none;
    opacity: 0;
    animation: fade-in 0.16s ease forwards 0.32s;
    max-width: 200px;
    min-width: 92px;
    position: absolute;
    font-size: 12px;
    top: -8px;
    padding: 4px 8px;
    background: $izo_black;
    left: 0%;
    transform: translateY(-100%);
    border-radius: 4px;
    box-shadow: 0px 8px 8px 0px rgba($izo_black, 0.32);
  }

  * {
    pointer-events: none;
  }

  &:hover {
    background: $izo_light_grey_2;
    .ReactionUsers {
      display: block;
    }
  }

  .emoji-mart-emoji {
    margin: 0 4px 0 2px;
  }

  #EmojiWrapper {
    flex-basis: 100%;
    margin: 8px 0;
  }
}

.MenuComment {
  .Reaction {
    margin: 4px;
  }

  .AddReaction {
    width: 32px;
    background: $izo_dark_grey_2;

    &:hover {
      background: $izo_dark_grey_3;
    }

    .emoji-mart-emoji {
      margin: 4px;
    }
  }
}

.TimelineComment {
  .AddReaction {
    width: 32px;
    background: $izo_dark_grey_2;
    transition: background 0.2s;

    &:hover {
      background: $izo_dark_grey_3;
    }

    .emoji-mart-emoji {
      margin: 2px;
    }
  }
  .emoji-mart-dark,
  .emoji-mart-category-label span {
    background: $izo_dark_grey_1 !important;
  }
  .emoji-mart-dark input {
    background: $izo_black !important;
  }
}

.emoji-mart-category-list button span {
  pointer-events: none;
}
