.drop-enter {
  max-height: 0px;
}
.drop-enter-active {
  max-height: 200px;
  transition: max-height 0.2s;
}
.drop-exit {
  max-height: 200px;
}
.drop-exit-active {
  max-height: 0px;
  transition: max-height 0.2s;
}
