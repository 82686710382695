@import 'styles/theme.scss';

#Crash {
  @include flexCenter;
  flex-direction: column;
  animation: fade-in 1s forwards ease;
  text-align: center;
  height: 100vh;
  width: 100%;
  flex-shrink: 0;
  z-index: 0;

  .Logo {
    position: absolute;
    top: 32px;
    left: 32px;
    width: 40px;
    height: 40px;
    transition: all 0.16s;
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
    }
  }

  .Primary {
    width: 100%;
    max-width: 640px;
    margin: 32px 0;
  }

  h3 {
    font-size: 28px;
    font-weight: 900;
    margin: 8px 16px;
    line-height: 1.2;
    @include mobile {
      font-size: 24px;
    }
  }

  p {
    font-size: 18px;
    line-height: 1.5;
    margin: 8px 16px;
    font-family: $fontHeader;
    strong {
      font-weight: 900;
    }
    @include mobile {
      font-size: 14px;
    }
    &.loading-delay {
      margin-top: 24px;
      opacity: 0;
      animation: fade-in-no-pointer-events 0.32s ease forwards 15s;

      strong {
        color: $izo_teal;
        text-decoration: underline;
        font-weight: 500;
        cursor: pointer;
      }
    }
  }

  #ErrorButtons {
    width: 100%;
    max-width: 640px;
    @include flexCenter;
    flex-wrap: wrap;
    margin-top: 16px;
    button {
      margin: 8px;
    }
    @include mobile {
      flex-direction: column;
    }
  }
}
