@import 'styles/theme.scss';

#LandingUserLookup {
  position: relative;
  width: 100%;

  .Header {
    #UserSearch {
      position: relative;
      input {
        font-size: 14px;
        margin: 4px 0 !important;
      }
      i,
      svg {
        position: absolute;
        top: 8px;
        right: 10px;
        cursor: pointer;
      }
    }
  }

  .User {
    width: 100%;
    margin-top: 16px;
    z-index: 1;
    .Info {
      @include flexCenter;
      justify-content: flex-start;
      width: 100%;
      img {
        width: 64px;
        height: 64px;
        object-fit: cover;
        background: $izo_dark_grey_3;
        border-radius: 50%;
        margin-right: 16px;
        flex-shrink: 0;
      }
      div {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;
        h3 {
          @include flexCenter;
          justify-content: flex-start;
          background: $izo_dark_grey_3;
          width: 100%;
          height: 32px;
          border-radius: 4px;
          padding: 2px 8px;
        }
        h6 {
          @include flexCenter;
          justify-content: flex-start;
          font-size: 12px;
          color: $izo_light_grey_2;
          background: $izo_dark_grey_3;
          width: 100%;
          line-height: 1;
          height: 20px;
          margin-top: 4px;
          border-radius: 4px;
          padding: 0 8px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        button {
          font-size: 12px;
          background: $izo_transparent;
          padding: 4px;
          width: 100%;
          border-radius: 4px;
          margin: 4px 0;
        }
      }
    }
    .Error {
      width: 100%;
      text-align: center;
      padding: 16px;
      color: $izo_red;
    }
    .Collections {
      z-index: 1;
      h4 {
        font-weight: 900;
        margin: 12px 0 4px 0;
      }
      button {
        width: 100%;
        margin-top: 4px;
        background: $izo_transparent;
      }
      ol {
        li {
          padding: 16px 4px;

          &:hover {
            cursor: pointer;
            background: $izo_dark_grey_3;
          }

          a {
            width: 100%;
            display: flex;
            @include flexCenter;
            justify-content: space-between;
          }

          h5 {
            font-size: 16px;
            color: $izo_teal;
            font-weight: 900;
            text-transform: none;
          }
          span {
            float: right;
            color: $izo_light_grey_2;
            font-size: 12px;
          }
          &:not(:last-child) {
            border-bottom: 1px solid rgba($izo_light_grey_2, 0.32);
          }
        }
      }
    }
  }
}
