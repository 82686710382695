@import 'styles/theme.scss';

#NoMatch {
  @include flexVerticalCenter;
  justify-content: flex-start;
  animation: fade-in 1s forwards ease;
  text-align: center;
  height: 100vh;
  width: 100%;
  flex-shrink: 0;
  z-index: 0;

  > img {
    width: 100%;
    max-width: 640px;
    margin: 32px 0;
  }

  h3 {
    font-size: 28px;
    font-weight: 900;
    margin: 8px 16px;
    line-height: 1.2;
    @include mobile {
      font-size: 24px;
    }
  }

  p {
    font-size: 18px;
    line-height: 1.5;
    margin: 8px 16px;
    color: $izo_light_grey_1;
    strong {
      font-weight: 900;
    }
    @include mobile {
      font-size: 14px;
    }
  }

  #ErrorButtons {
    width: 100%;
    max-width: 640px;
    @include flexCenter;
    flex-wrap: wrap;
    margin-top: 16px;
    button {
      margin: 8px;
    }
    @include mobile {
      flex-direction: column;
    }
  }
}
