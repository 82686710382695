@import 'styles/theme.scss';

#Loading {
  @include flexVerticalCenter;
  width: 100vw;
  height: 100vh;
  height: var(--vh, 100vh);
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: 0;
  animation: fade-in 1s forwards ease 1s;

  &.relative {
    height: 100%;
    width: 100%;
    padding: 16px;
    position: relative;
    top: unset;
    left: unset;
  }

  &.noDelay {
    animation: fade-in 0.4s forwards ease;
  }

  .AnimationWrapper {
    background: $izo_dark_grey_1;
    border-radius: 50%;
    overflow: hidden;
    animation: color-pulse infinite 7.57s;
    flex-shrink: 0;
    max-width: 100%;
    max-height: 100%;
  }

  img {
    width: 160px;
    animation: pulse 2.4s infinite ease 1s;
    border-radius: 50%;
  }

  h3 {
    margin-top: 32px;
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 0.32;
    }
    50% {
      transform: scale(1.2);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 0.32;
    }
  }
}
