@import 'styles/theme.scss';

#Dropzone {
  @include flexCenter;
  flex-direction: column;
  height: 100%;
  min-height: 16vh;
  width: 100%;
  background: $izo_black;
  border-radius: 8px;
  cursor: pointer;
  font-family: $fontHeader;
  outline: none;
  background: rgba($izo_white, 0.1);

  p {
    margin: 8px;
    text-decoration: underline;
    color: rgba($izo_white, 0.8);
  }
}
