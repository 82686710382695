@import 'styles/theme.scss';

#AdminLanding {
  position: relative;
  width: 100%;
  padding: 16px 0 32px 0;

  .LandingTiles {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    grid-auto-rows: calc(44vh - 64px);
    grid-gap: 16px;
    grid-auto-flow: dense;
    @media (max-width: $mobile) {
      grid-auto-rows: auto;
    }

    .Tile {
      background: $izo_dark_grey_1;
      width: 100%;
      border-radius: 8px;
      padding: 16px;
      overflow-y: auto;
      overflow-x: hidden;

      @media (max-width: $mobile) {
        padding: 0;
        padding: 24px 0 64px 0;
        background: none;
        border-radius: 0;
        &:first-child {
          padding-top: 0;
        }
        &:not(:last-child) {
          border-bottom: 4px solid $izo_dark_grey_3;
        }
      }

      p {
        color: $izo_light_grey_2;
        margin-bottom: 8px;
        @include ellipsis;

        @media (max-width: $mobile) {
          overflow: visible;
          white-space: unset;
          line-height: 1.1;
          margin: 8px 0 24px 0;
        }
      }

      .izo_input {
        margin: 8px 0;
        input,
        textarea {
          background: $izo_black;
        }
      }
    }
  }
}
