@import 'styles/theme.scss';

.izo_heading {
  position: relative;
  font-family: $fontHeader;
  color: $izo_white;
  line-height: 1.2;
  margin: 0;
  padding: 0;
  max-width: 100%;
  flex-shrink: 0;

  &.fullWidth {
    width: 100%;
  }

  &:not(.wrap) {
    @include ellipsis;
  }

  &.center {
    @include flexCenter;
    text-align: center;
  }

  &.left {
    @include flexLeft;
    text-align: left;
  }

  &.right {
    @include flexRight;
    text-align: right;
  }
}

h1.izo_heading {
  font-size: 48px;
  font-weight: 900;
}

h2.izo_heading {
  font-size: 36px;
  font-weight: 900;
}

h3.izo_heading {
  font-size: 32px;
  font-weight: 900;
}

h4.izo_heading {
  font-size: 24px;
  font-weight: 900;
}

h5.izo_heading {
  font-size: 18px;
  font-weight: 900;
}

h6.izo_heading {
  font-size: 16px;
  font-weight: 500;
}
