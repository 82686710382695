@import 'styles/theme.scss';

#UserMenu {
  position: relative;
  overflow-y: visible;
  overflow-x: hidden;
  @include flexVerticalLeft;
  border-radius: 20px;
  transition: background 0.2s;
  // padding: 4px;
  cursor: pointer;
  flex-shrink: 0;
  background: $izo_white;

  &.in-menu {
    margin: 26px 0 0 16px;
    width: calc(100% - 80px);
  }

  &.in-header {
    // handle not jumping above header container
    align-self: flex-start;
    margin-top: 6px;
    margin-left: auto;
    flex-shrink: 1;
  }

  #UserButton {
    @include flexLeft;
    max-width: 100%;
    padding: 4px 8px 4px 4px;
    h5 {
      color: $izo_black;
      margin-left: 8px;
      font-size: 16px;
      padding-right: 8px;
      width: calc(100% - 32px);
      max-width: 300px;
    }
  }

  #UserDropdown {
    width: 100%;
    padding: 8px;
    li {
      color: $izo_black;
      padding: 12px 0;
      font-size: 14px;
      font-weight: 400;
      transition: color 0.2s;

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          color: $izo_blue;
        }
      }

      &:not(:last-child) {
        border-bottom: 1px solid rgba($izo_light_grey_1, 0.4);
      }

      svg {
        width: 14px;
        margin-right: 14px;
        margin-left: 2px;
      }
    }
  }
}
