@import 'styles/theme.scss';

#AdminStems {
  position: relative;
  width: 100%;
  height: 100%;
  @include flexVerticalCenter;

  #AdminAudioViews.tracks #Tracks {
    padding: 0 0 64px 0;
    .Waveform .Background {
      background: rgba($izo_dark_grey_1, 0.48) !important;
      opacity: 1;
    }
  }
}
