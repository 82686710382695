@import 'styles/theme.scss';

#LandingCollections {
  grid-row: span 2;
  position: relative;
  overflow: hidden !important;

  .Header {
    @include flexCenter;
    justify-content: space-between;
  }

  .Loading {
    width: 100%;
    text-align: center;
    padding: 64px 0;
    font-size: 18px;
    font-weight: 900;
    color: $izo_light_grey_2;
  }

  .Error {
    width: 100%;
    text-align: center;
    padding: 64px 0;
    font-size: 18px;
    font-weight: 900;
    color: $izo_red;
  }

  #CollectionSearch {
    position: relative;
    i,
    svg {
      position: absolute;
      top: 6px;
      right: 10px;
      cursor: pointer;
    }
  }

  ol {
    height: calc(100% - 92px);
    overflow-y: auto;
    padding-bottom: 84px;

    @media (max-width: $mobile) {
      padding-bottom: 32px;
    }

    li {
      padding: 16px 4px;
      width: 100%;
      display: flex;
      @include flexCenter;
      justify-content: space-between;

      a {
        max-width: 60%;
      }

      h5 {
        font-size: 16px;
        color: $izo_teal;
        text-decoration: underline;
        @include ellipsis;

        &:hover {
          white-space: unset;
        }

        &.Private {
          color: $izo_red;
        }
      }

      .CollectionInfo {
        max-width: 44%;
        float: right;
        color: $izo_light_grey_2;
        font-size: 12px;
        text-align: right;
        span {
          display: block;
        }
        div {
          display: inline-block;
          cursor: pointer;
          margin-top: 4px;
          text-decoration: underline;
          color: $izo_blue;
        }
      }

      &:not(:last-child) {
        border-bottom: 1px solid rgba($izo_light_grey_2, 0.32);
      }
    }
  }

  .ViewCollections {
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 0;
    padding: 24px;
    width: 100%;
    background: $izo_dark_grey_2;
    @media (max-width: $mobile) {
      background: none;
    }
  }
}
