@import 'styles/theme.scss';

.song .MuteButton {
  visibility: hidden;
  pointer-events: none;
}

.MuteButton {
  position: relative;
  vertical-align: top;
  height: 18px;
  width: 18px;
  padding: 0;
  margin: 0 8px 0 0;
  background: none;
  border-radius: 50%;
  overflow: hidden;
  animation: fade-in 0.32s ease;
  z-index: 1;
  cursor: pointer;

  &.on {
    background: $izo_red !important;
  }

  img {
    pointer-events: none;
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: all 0.32s ease;
  }
}
