@import 'styles/theme.scss';

#AdminAudioViews {
  position: relative;
  @include flexVerticalCenter;
  justify-content: flex-start;
  width: 100%;
  height: calc(100% - (#{$headerSize} + #{$footerSize}));
  overflow: hidden;
  animation: fade-in 0.32s ease;

  @media (max-width: $mobile) {
    height: calc(100% - (#{$headerSizeMobile} + #{$footerSizeMobile}));
  }

  #Tracks {
    width: 100%;
    height: 100%;
    overflow-y: auto;

    .Track {
      position: relative;
      width: 100%;
      overflow: visible;
      transition: height 0.32s, background 0.32s, opacity 0.32s ease;
      @include flexLeft;
      align-items: flex-start;
      flex-shrink: 0;

      .TrackHeader {
        @include flexCenter;
        position: absolute;
        top: -32px;
        left: 4px;
        justify-content: flex-start;
        z-index: 1;

        .TrackLabel {
          margin-right: 8px;
          font-weight: 900;
          text-shadow: 0px 1px 2px $izo_dark_grey_1;
        }
      }
    }
  }

  &.song {
    .Track {
      height: 0;
      top: calc(50% - 24vh);
      transition: height 0.32s, background 0.32s;

      .TrackHeader {
        transition: opacity 0s;
        opacity: 0;
      }
    }
  }

  &.tracks {
    #Tracks {
      overflow-x: hidden;
      .Track {
        height: calc(16vh - 34px);
        top: unset;
        margin-top: 44px;
        .TrackHeader {
          transition: opacity 0.32s 0.32s;
        }

        .Waveform {
          height: 100%;
          border-radius: 8px;
        }
      }
    }
  }

  &.clips {
    #Tracks {
      overflow-x: hidden;
      padding: $viewButtonsSize 0 108px 0;
      .Track {
        height: calc(16vh - 34px);
        top: unset;
        margin-top: 44px;
        .TrackHeader {
          transition: opacity 0.32s 0.32s;
        }

        .Waveform {
          height: 100%;
          border-radius: 8px;
        }
      }
    }
  }
}
