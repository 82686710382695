@import 'styles/theme.scss';

#MissingAudio {
  p {
    width: 100%;
    text-align: center;
  }
  #ButtonContainer {
    @include flexCenter;
    .izo_button {
      margin: 4px 8px;
    }
    @include mobile {
      @include flexVerticalCenter;
    }
  }
}
