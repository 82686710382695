@import 'styles/theme.scss';

#Search {
  @include flexCenter;
  position: relative;
  select {
    border: 0;
    font-size: 14px;
    background: $izo_dark_grey_1;
    padding: 12px 8px;
    color: $izo_blue;
    margin-right: 8px;
    border-radius: 8px;
    text-transform: capitalize;

    option {
      border-radius: 32px 0 0 32px;
    }
  }
}
