@import 'styles/theme.scss';

#Header {
  position: relative;
  width: 100%;
  height: $headerSize;
  padding: 24px 0;
  @include flexLeft;
  z-index: 20;
  overflow-y: visible;
  box-shadow: 0 8px 24px 0 rgba($izo_black, 0.8);

  @include mobile {
    height: $headerSizeMobile;
    padding: 8px 0;
  }

  &.withBackground {
    background: linear-gradient(
      to bottom,
      rgba($izo_black, 1),
      rgba($izo_black, 0)
    );
    @include mobile {
      background: $izo_black;
    }
  }

  #HeaderChildren {
    flex: 1 1 auto;
    height: 100%;
    align-self: flex-start;
    @include flexLeft;
    overflow: hidden;
  }

  #UserProfile {
    margin-left: auto;
    align-self: flex-start;
  }

  #MenuButton {
    background: none;
    padding: 0;
    margin: 0;
    margin-left: auto;
    > img {
      pointer-events: none;
      height: 32px;
      width: 32px;
      cursor: pointer;
    }
  }

  #HomeButton {
    position: relative;
    height: 44px;
    width: 44px;
    border-radius: 50%;
    flex-shrink: 0;
    object-fit: scale-down;
    overflow: hidden;
    transition: all 0.2s ease;
    margin-right: 16px;
    border-radius: 50%;
    padding: 0;
    background: none;
    cursor: pointer;

    @include mobile {
      height: 36px;
      width: 36px;
      margin-right: 8px;
    }

    .withAnimation {
      &:after {
        content: '';
        position: absolute;
        z-index: -1;
        background: $izo_spire_gradient;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        opacity: 0;
        transition: opacity 0.2s ease;
      }

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          transform: scale(1.05) rotate(-10deg);
          transform-origin: center;
          padding: 2px;
          &:after {
            opacity: 1;
          }
        }
      }
    }
  }

  h2 {
    font-size: 32px;
    @include mobile {
      font-size: 24px;
    }
  }
}

#Home #Header,
#Landing #Header {
  box-shadow: none;
}
