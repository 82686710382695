@import 'styles/theme.scss';

#TabContainer {
  width: 100%;
  height: calc(100% - 350px);
}

#AdminTabs {
  width: 100%;
  @include flexLeft;
  padding-top: 24px;

  div {
    padding: 16px 8px;
    margin-right: 32px;
    position: relative;
    width: 70px;
    text-align: center;
    &:hover {
      .izo_heading {
        color: $izo_white;
      }
    }

    .izo_heading {
      color: $izo_light_grey_1;
      text-transform: capitalize;
      cursor: pointer;
    }

    &.selected {
      .izo_heading {
        color: $izo_white;
      }
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 8px;
        background: $izo_light_grey_1;
      }
    }
  }
}
