@import 'styles/theme.scss';

#SelectTheme {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 24px;

  @include mobile {
    flex-direction: column;

    h6 {
      text-align: center;
      font-size: 16px;
      margin-bottom: 8px;
    }
  }

  h6 {
    margin-right: 8px;
    font-weight: 600;
  }

  &.isAdmin {
    padding: 0;
  }

  #Colors {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .Color {
    flex-shrink: 0;
    height: 16px;
    width: 16px;
    margin: 4px;
    border-radius: 50%;
    cursor: pointer;
    box-sizing: border-box;

    &.selected {
      border: 2px solid #fff;
    }

    &.loading-change {
      border-top: 2px solid #fff;
      animation: select-animate 0.6s infinite ease;
    }
  }

  @keyframes select-animate {
    0% {
      border: 2px solid rgba(255, 255, 255, 0);
    }

    50% {
      border: 2px solid rgba(255, 255, 255, 1);
    }

    100% {
      border: 2px solid rgba(255, 255, 255, 0);
    }
  }
}
