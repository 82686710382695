@import 'styles/theme.scss';

#AdminCollectionHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  .Header {
    width: 100%;
    @include flexCenter;
    justify-content: flex-start !important;
  }

  .ButtonContainer {
    margin-left: auto;
    @include flexCenter;

    button {
      i,
      svg {
        color: #b6bfc6;
        font-size: 20px;

        &.fa-check {
          color: $izo_teal;
        }
      }
    }
  }

  h1 {
    padding: 8px 0;
    margin: 0;
    font-weight: 900;
    letter-spacing: 0;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;

    &:hover {
      white-space: normal;
    }
  }

  h3 {
    font-size: 18px;
    font-family: $fontBody;
    color: $izo_light_grey_1;
    height: 28px;
    margin-top: 4px;
    font-weight: 400;
  }

  .Back {
    color: $izo_white;
    @include flexCenter;
    h4 {
      margin: 8px;
      font-weight: 900;
    }
    i,
    svg {
      font-size: 16px;
      color: $izo_light_grey_2;
    }
  }

  #UserDetails {
    position: relative;
    height: 24px;
    @include flexLeft;
    h4 {
      // display: inline !important;
      font-weight: 900;
      background: $izo_teal;
      padding: 2px 4px;
      border-radius: 4px;
      font-size: 12px;

      &.privatePath {
        background: $izo_red;
      }
    }
    h6 {
      // display: inline !important;
      position: relative;
      display: inline;
      font-size: 12px;
    }

    #CollectionUserId {
      cursor: pointer;
      &:hover {
        color: $izo_teal;
      }
    }

    i,
    svg {
      margin: 0 4px 0 8px;
    }
  }
}
