@import 'styles/theme.scss';

.Waveform {
  position: absolute;
  height: 48vh;
  overflow: hidden;
  transition: opacity 0.16s, height 0.32s;
  z-index: 1;

  &.muted {
    .Wave,
    .Elapsed {
      opacity: 0.32 !important;
    }
  }

  &.loading {
    pointer-events: none;
    border-radius: 2px;
    .Background {
      filter: grayscale(1);
      opacity: 0.2 !important;
    }
  }

  &.failed {
    .FailedContainer {
      cursor: pointer;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      border-radius: 8px;
      border: 4px solid $izo_red;
    }
    .Background {
      filter: grayscale(1);
      opacity: 0.2 !important;
    }
  }

  .Background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.16s ease;
    border-radius: 8px;
  }

  .Wave {
    position: absolute;
    left: 0;
    width: 100%;
    z-index: -1;
    animation: grow-in 0.32s ease-in-out forwards;
    transition: opacity 0.32s ease, clip-path 0.64s ease;
    clip-path: polygon(0% 25%, 100% 25%, 100% 75%, 0% 75%, 0% 25%);
  }

  .ErrorIcon {
    height: 48px;
    width: 48px;
    max-height: 50%;
    max-width: 80%;
    object-fit: contain;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .Elapsed {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    transition: background 0.16s, opacity 0.16s, width 0.1s;
    &.playing {
      transition: background 0.16s, opacity 0.16s, width 0.5s linear;
    }
    &.scrubbed {
      transition: background 0.16s, opacity 0.16s, width 0.2s ease;
    }
  }

  @keyframes grow-in {
    from {
      top: 50%;
      height: 0;
    }
    to {
      top: 0;
      height: 100%;
    }
  }
}

.song .Waveform {
  transition: background 0.16s 0s, opacity 0.16s, height 0.32s;
  &.muted {
    z-index: 0;
    .Wave,
    .Elapsed {
      opacity: 1 !important;
      background: $izo_dark_grey_1 !important;
      z-index: -1;
    }
  }
}

.clips .Background {
  animation: fade-in 0.16s ease forwards 0.16s;
}

#Minimap .Background {
  display: none;
}

.loading .Track .Waveform:not(.loading) .Background {
  // display: none;
}

@keyframes spin {
  from {
    transform: translate(-50%, -50%) rotate(0);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
